import throttle from "lodash/throttle";
import assignIn from "lodash/assignIn";


require('../polyfills/object-assign');
require('../polyfills/find-polyfill');
require('../../../node_modules/bxslider/dist/jquery.bxslider.js');


/**
 * exampleRotator = new initBxSlider('example-rotator');
 * exampleRotator.options = {};
 * exampleRotator.callback = function(slider) {};
 * exampleRotator.init();
 */
export default function initBxSlider(className) {
    this.sliders = [];

    this.options = {
        speed: (window.innerWidth <= 767) ? 300 : 800,
        pause: 6000,
        mode: (window.innerWidth <= 767) ? 'horizontal' : 'fade',
        minSlides: 1,
        maxSlides: 1,
        infiniteLoop: true,
        auto: true,
        useCSS: !(window.innerWidth <= 767),
        controls: !(window.innerWidth <= 767),
        touchEnabled: (window.innerWidth <= 767),
        oneToOneTouch: (window.innerWidth <= 767)
    };

    this.callback = function (slider) {
        return {
            onSliderLoad: function () {
                slider.addClass('loaded');
            },
        }
    };

    this.init = function (options, callback) {
        assignIn(this.options, options);
        this.callback = callback || this.callback;
        this.attachBxSliderToEachOfClass();
        return this.sliders;
    };

    this.initWithSizeRange = function (min, max, options, callback) {
        // ToDo: this is a little daft lets set some parameters and check them in init or something
        assignIn(this.options, options);
        this.callback = callback || this.callback;
        let $window = jQuery(window);
        this.windowWidth = $window.width();
        this.checkSize($window, min, max);
        this.resizeEventListener();
        $window.on('resizeEnd', (() => {
            this.deinitialiseBxSliders();
            this.checkSize($window, min, max);
        }));
    };

    this.attachBxSliderToEachOfClass = function () {
        let i = 1;
        let obj = this;
        jQuery('.' + className).each(function () {
            let $class = jQuery(this);
            let id = createID(i, $class);
            obj.disableControlsIfSingleSlide($class, obj.options);
            obj.initialiseBxSlider(id);
            i++;
        });
    };

    this.checkSize = function ($window, min, max) {
        if ($window.width() >= min && $window.width() <= max || max === -1) {
            this.attachBxSliderToEachOfClass();
        }
    };

    this.resizeTrigger = function () {
        if ($(window).width() < (this.windowWidth - 5) || $(window).width() > (this.windowWidth + 5)) {
            $(this).trigger('resizeEnd');
        }
        this.windowWidth = $(this).width();
    };

    this.resizeEventListener = function () {
        $(window).resize(throttle(this.resizeTrigger, 50));
    };

    this.sliderExists = function (id) {
        return this.sliders.find((x) => x.selector === "#" + id);
    };

    this.initialiseBxSlider = function (id) {
        if (!this.sliderExists(id)) {
            const slider = jQuery('#' + id);
            const mergedOptions = Object.assign(this.options, this.callback(slider));
            slider.bxSlider(mergedOptions);
            this.sliders.push(slider);
        }
    };

    this.disableControlsIfSingleSlide = function ($this) {
        if ($this.children().length <= 1) {
            this.options["controls"] = false;
            this.options["pager"] = false;
            this.options["touchEnabled"] = false;
        }
    };

    function createID(i, $this) {
        const id = className + '-' + i;
        $this.attr('id', id);
        return id;
    }

    this.deinitialiseBxSliders = function () {
        this.sliders.map(x => x.destroySlider());
        this.sliders = [];
    }
};

import map_styles from '../json/google-map-styles'

export default class GoogleMap {

    constructor(data) {
        this.data = data;
        const myOptions = {
            zoom: data.zoomLevel,
            maxZoom: 0,
            minZoom: 6,
            scrollwheel: false,
            mapTypeControl: false,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.LARGE,
                position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            streetViewControl: false,
            center: new google.maps.LatLng(data.centreLat, data.centreLong),
            mapTypeId: google.maps.MapTypeId.MAP,
            styles: map_styles
        };

        this.mapobject = new google.maps.Map(document.getElementById(data.uniqueID), myOptions);
    }

    initMap() {
        this.data.pins.map(pin => {
            let pinIcon;
            switch (pin.type) {
                case 'one':
                    pinIcon = new google.maps.MarkerImage(phpVars.templateUrl + '/static/img/map-pin-1.png', null, null, new google.maps.Point(28, 40));
                    break;
                case 'two':
                    pinIcon = new google.maps.MarkerImage(phpVars.templateUrl + '/static/img/map-pin-2.png', null, null, new google.maps.Point(28, 40));
                    break;
                case 'three':
                    pinIcon = new google.maps.MarkerImage(phpVars.templateUrl + '/static/img/map-pin-3.png', null, null, new google.maps.Point(28, 40));
                    break;
            }

            new google.maps.Marker({
                icon: pinIcon,
                position: new google.maps.LatLng(pin.lat, pin.long),
                map: this.mapobject
            });
        });
    }
}


import axios from "axios/index";
import GoogleMap from "../functions/google-map";

export const googleMapAPICall = function () {
    const maps = document.getElementsByClassName('google-map');
    if (maps.length > 0) {
        axios.get('/wp-json/kl/v1/google-map/' + phpVars.id)
            .then(function (response) {
                response.data.map(data => {
                    let googleMap = new GoogleMap(data);
                    googleMap.initMap();
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

import 'leaflet'

export default class Masterplan {
    constructor(data) {
        console.log(data);
        this.L = window.L;
        this.plans = data;
    }

    init() {
        this.plans
            .map(plan => this.createMap(plan));
    }

    createMap(plan) {
        const bounds = [[0, 0], [plan.interactivePlanImage.height, plan.interactivePlanImage.width]];
        const map = this.L.map(plan.uniqueID, {
            crs: this.L.CRS.Simple,
            minZoom: -0.85,
            maxZoom: 0,
            zoomDelta: 0.50,
            zoomSnap: 0.50,
            maxBounds: bounds,
            maxBoundsViscosity: 0.88,
            attributionControl: false
        }).fitBounds(bounds);
        this.L.imageOverlay(plan.interactivePlanImage.url, bounds).addTo(map);
        plan.pins.map((pin) => {
            const latLon = this.L.latLng([pin.lng, pin.lat]);
            const mapPin = this.L.marker(latLon).addTo(map);
            mapPin.bindTooltip('<div class="mapPopUp">'+pin.label+'</div>', {'className': 'kl-tool-tip'});
        });
        plan.overlays.map((overlay) => {
            if(overlay.is_active) {
                const plot = this.L.imageOverlay(overlay.image.url, bounds);
                plot.addTo(map);
            }
        })
    }
}

export default function galleryRotator() {
    $('.gallery-item').click(function (e) {
        e.preventDefault();
        const galleryContainer = $(this).parent();
        const rotator = $('#' + galleryContainer.data('id'));
        const overlay = rotator.parent();
        const slideId = $(this).data('slide');
        overlay.fadeIn();
        rotator.bxSlider().goToSlide(slideId);
        overlay.find('.close-button').click(() => {
            overlay.fadeOut(function () {
                rotator.destroySlider();
            });
        })
    });
};

export function drawerToggle() {
    $('#main-menu-toggle-open').click(function () {
        $('#main-nav').addClass('open');
        $('#main-menu-toggle').addClass('open');
    });
    $('#main-menu-toggle-close').click(function () {
        $('#main-nav').removeClass('open');
        $('#main-menu-toggle').removeClass('open');
        $('.sub-menu').slideUp();
    });
    $('#main-menu-toggle').click(function () {
        $(this).toggleClass('open');
        const mainNav = $('#main-nav');
        mainNav.toggleClass('open');
        if (!mainNav.hasClass('open')) {
            $('.sub-menu').slideUp();
        }
    });
}

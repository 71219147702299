export default function initAccordion() {

    $('.accordion-title').on("click", function(){
        if(!$(this).next().is(':visible')){
            $('.accordion-text').slideUp();
        }
        $(this).next().slideToggle();
    });

}

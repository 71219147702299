export function mobileMenuToggle() {
    $('#nav-toggle').click(function () {
        $(this).toggleClass('active');
        $('.header-nav').slideToggle();
    });
}

export function dropDownLinks(targetScreenWidth) {
    const targetElement = $('.menu-item-has-children > a');
    if ($(window).width() <= targetScreenWidth || typeof targetScreenWidth == 'undefined') {
        targetElement.off('click');
        targetElement.click(function (e) {
            e.preventDefault();
            $('.sub-menu').slideUp();
            if ($(this).siblings().first().css('display') === 'block') {
                $(this).siblings().first().slideUp();
                targetElement.removeClass('dropdown');
                targetElement.addClass('resetArrow');
            } else {
                $(this).siblings().first().slideDown();
                targetElement.addClass('dropdown');
                targetElement.removeClass('resetArrow');
            }
        });
    } else {
        targetElement.off('click');
        $('.sub-menu').show();
    }
}


export function scrollToSection(buttonId, sectionId) {
    $(buttonId).on("click", function () {
        document.querySelector(sectionId).scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: 'smooth'
        });
    });
}

export function scrollToTopButton(selector = '#back-to-top') {
    $(selector).click(function () {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    });
}

export function disableScrollBar() {
    $('html').css({
        'overflowY': 'scroll', 'position': 'fixed', 'width': '100%'
    });
}

export function enableScrollBar() {
    $('html').css({
        'overflowY': 'auto', 'position': 'static'
    });
}

export default function magicLine() {
    let $el, leftPos, newWidth,
    $mainNav = $("#menu-main-menu");
    
    if ($("#magic-line").length < 1 && $(".current-menu-item")[0]){
        $mainNav.append("<li id='magic-line'></li>");
    }

    let $magicLine = $("#magic-line");

    // Check for magicLine
    if (!$magicLine.length) {
        return;
    } else {
        $magicLine
        .width($(".primary.current_page_item, .primary.current-page-ancestor").width())
        .css("left", $(".primary.current_page_item, .primary.current_page_parent").position().left)
        .data("origLeft", $magicLine.position().left)
        .data("origWidth", $magicLine.width());
    }
            
    $(".primary").hover(function() {
        $el = $(this);
        leftPos = $el.position().left;
        newWidth = $el.width();
        $magicLine.stop().animate({
            left: leftPos,
            width: newWidth
        });
    }, function() {
        $magicLine.stop().animate({
            left: $magicLine.data("origLeft"),
            width: $magicLine.data("origWidth")
        });    
    });
}

import axios from "axios/index";
import Masterplan from "../functions/masterplan";

export const masterplanAPICall = function () {
    const masterplans = document.getElementsByClassName('masterplan');
    if (masterplans.length > 0) {
        axios.get('/wp-json/kl/v1/masterplan/' + phpVars.id)
            .then(function (response) {
                response.data.map(data => {
                    let masterPlan = new Masterplan(data);
                    masterPlan.init();
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

export default class newsletterForm {
    constructor(buttonSelector) {
        this.buttonSelector = buttonSelector;
        const html = `
            <div class="newsletter-sign-up-holder" id="newsletter-signup-form-overlay">
                <div id="newsletter-close" class="newsletter-close hamburger-icon open">
                    <span></span> <span></span> <span></span> <span></span>
                </div>
                <div class="newsletter-sign-up-form">
                    <form method="post" action="/" id="newsletter-signup-form">
                        <label for="newsletter-name">Full Name</label>
                        <input id="newsletter-name" name="name" type="text" title="Full Name"">
                        <label for="newsletter-email">Email</label>
                        <input id="newsletter-email" name="email" type="email" title="Email Address">
                        <input type="submit" value="Subscribe" id="subscribe-to-newsletter" class="button">
                    </form>
                    <div id="newsletter-message" class="newsletter-message"></div>
                </div>
            </div>
        `;
        this.overlay = $($.parseHTML(html));
    }

    init() {
        $(this.buttonSelector).on("click", () => {
            this.createNewsletterForm();
            setTimeout(() => {
                    $('#newsletter-signup-form-overlay').toggleClass('open')
                },
                100)
        });
    }

    createNewsletterForm() {
        $(this.overlay).appendTo('body');
        this.newsletterSignupForm();
        this.newsletterSignUpClose();
    }

    newsletterSignupForm() {
        $('#subscribe-to-newsletter').click((e) => {
            e.preventDefault();
            let data = {
                'name': $('#newsletter-name').val(),
                'email': $('#newsletter-email').val()
            };
            $.ajax({
                type: "post",
                url: "/wp-json/kl/v1/subscribe/",
                data: JSON.stringify(data),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (data) {
                    $('#newsletter-signup-form').fadeOut();
                    $('#newsletter-message').hide().html('<p class="success">' + data.message + '</p>');
                    setTimeout(function () {
                        $('#newsletter-message').fadeIn();
                    }, 400);
                    setTimeout(function () {
                        $('#newsletter-signup-form-overlay').removeClass('open');
                    }, 1600);
                },
                error: function (errMsg) {
                    $('#newsletter-message').html('<p class="error">' + errMsg.responseJSON.message + '</p>');
                }
            });
        });
    }

    newsletterSignUpClose() {
        $('#newsletter-close').on("click", () => {
            this.overlay.toggleClass('open');
            setTimeout(() => {
                this.overlay.remove();
            }, 1000);
        });
    };
}

import $ from 'jquery';
import '../sass/main.scss';
import AOS from 'aos';
import * as plyr from 'plyr';

require('picturefill');
// require('smoothscroll-polyfill').polyfill();
require('./polyfills/find-polyfill');

import initAccordion from "./functions/accordion";
import {drawerToggle} from "./functions/slider-drawer";
import {mobileMenuToggle} from "./functions/mobile-menu-toggle";
import magicLine from "./functions/magic-line";
import {dropDownLinks} from "./functions/mobile-menu-toggle";
import initBxSlider from './functions/bx-slider-init';
import {scrollToSection} from './functions/scroll';
import newsletterForm from './functions/newsletter';
import {googleMapAPICall} from './api/google-map';
import galleryRotator from './functions/gallery-rotator';
import {masterplanAPICall} from "./api/masterplan";

$(window).on('load', function () {
    AOS.init();
});

$(document).ready(function () {
    initAccordion();
   //googleMapAPICall();
    masterplanAPICall();
    drawerToggle();
    magicLine();
    mobileMenuToggle();
    dropDownLinks(767);
    scrollToSection('#next-section-button', '#next-section');
    const imageRotator = new initBxSlider('image-rotator');
    imageRotator.init();
    const teamRotator = new initBxSlider('team-rotator');
    teamRotator.initWithSizeRange(-1, -1, {
        speed: 500,
        pause: 8000,
        mode: 'horizontal',
        maxSlides: (window.innerWidth <= 767) ? 1 : 5,
        moveSlides: 1,
        slideWidth: (window.innerWidth <= 767) ? 500 : 250,
        shrinkItems: true,
        infiniteLoop: false,
        auto: false,
        pager: false,
        controls: true
    });
    const newsletter = new newsletterForm('#newsletter-open');
    newsletter.init();
    galleryRotator();
    const options = {};
    plyr.setup(document.querySelectorAll('.js-player'), options);
});


$(window).resize(function () {
    magicLine();
    dropDownLinks(767);
});